import { createContext } from 'react';
import IPopup from '../popup';


const defaultState = {
   popupVisibility: false,
   updatePopupVisibility: (visible: boolean) => {},
   popupTheme: '',
   updatePopupTheme: (theme: string) => {},
   popupTitle: '',
   updatePopupTitle: (title: string) => {},
   popupMessage: '',
   updatePopupMessage: (message: string) => {}
};

const PopupContext = createContext<IPopup>(defaultState);

export default PopupContext