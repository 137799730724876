import React, { useEffect } from 'react'
import '../../styles/style.css';


interface UserButtonProps {
  signedUser: string,
  updateUser: () => void
}

const UserButton: React.FunctionComponent<UserButtonProps> = props => {

    
    useEffect(() => {

    }, [])


    return(
        <div className = 'userButton' onClick = {() => props.updateUser()}>
          <div className = 'userButtonText'>{props.signedUser}</div>
        </div>
        
     
    )

}

export default UserButton