import React, { useEffect } from 'react'
import '../../styles/style.css';


interface AreaButtonProps {
  clickedArea: string,
  updateArea: () => void
}

const AreaButton: React.FunctionComponent<AreaButtonProps> = props => {

    
    useEffect(() => {

    }, [])


    return(
        <div className = 'areaButton' onClick = {() => props.updateArea()}>
          <div className = 'areaButtonText'>{props.clickedArea}</div>
        </div>
        
     
    )

}

export default AreaButton