import { createContext } from 'react';
import IKnowledges from '../interfaces/knowledgeInterface'


const defaultState = {
   knowledges: [{ id: 0, areaid: 0, tasktype: 0, tasktitle: '', taskicon: 0, tasktext: '', taskpicture: '', taskpicturetext: ''}],
   updateKnowledges: () => {},
   actualArea: 0,
   updateActualArea: (areaid: number) => {},
   actualKnowledge: [{ id: 0, areaid: 0, tasktype: 0, tasktitle: '', taskicon: 0, tasktext: '', taskpicture: '', taskpicturetext: ''}],
   updateActualKnowledge: () => {},
};

const KnowledgeContext = createContext<IKnowledges>(defaultState);

export default KnowledgeContext