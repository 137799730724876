import React, { useEffect, useState, useContext } from 'react'
import PopupContext from '../../models/contexts/popupContext';
import KnowledgeContext from '../../models/contexts/knowledgeContext'
import DandDView11 from './DandDViews/DandDView11'
import DandDView12 from './DandDViews/DandDView12'
import DandDView13 from './DandDViews/DandDView13'
import DandDView21 from './DandDViews/DandDView21'
import DandDView31 from './DandDViews/DandDView31'
import DandDView32 from './DandDViews/DandDView32'
import DandDView33 from './DandDViews/DandDView33'
import DandDView41 from './DandDViews/DandDView41'
import DandDView51 from './DandDViews/DandDView51'
import DandDView52 from './DandDViews/DandDView52'
import DandDView53 from './DandDViews/DandDView53'
import DandDView54 from './DandDViews/DandDView54'
import DandDView55 from './DandDViews/DandDView55'
import DandDView56 from './DandDViews/DandDView56'
import DandDView57 from './DandDViews/DandDView57'
import DandDView61 from './DandDViews/DandDView61'
import DandDView62 from './DandDViews/DandDView62'
import DandDView71 from './DandDViews/DandDView71'
import DandDView72 from './DandDViews/DandDView72'

interface TaskDandDProps {
    taskObject: {
        background: string,
        taskType: string,
        taskTitle: string,
        taskIcon: string,
        taskText: string,
        taskPictureOrientation: string,
        taskPicture: string,
        taskPictureText: string,
        areaId: number,
        knowledgeId: number
    },
    targetArray: string[],
    targetArray2: string[],
    draggableArray: string[],

}

const TaskDandD: React.FunctionComponent<TaskDandDProps> = (props) => {

    const { actualKnowledge } = useContext(KnowledgeContext)

    useEffect(() => {

        
    }, [actualKnowledge])

    const getTask = (areaId: number, knowledgeId: number) => {
        switch (areaId) {
            case 1: {        
                switch (knowledgeId) {
                case 30: return <DandDView11 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } taskObject = { props.taskObject }/>;
                case 51: return <DandDView12 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } taskObject = { props.taskObject }/>;
                case 63: return <DandDView13 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } taskObject = { props.taskObject }/>;
                default: return null
            }};
            case 2: {        
                switch (knowledgeId) {
                    case 87: return <DandDView21 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } taskObject = { props.taskObject }/>;
                default: return null
            }};
            case 3: {        
                switch (knowledgeId) {
                case 13: return <DandDView31 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } />;
                case 14: return <DandDView32 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } />;
                case 18: return <DandDView33 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } />;
                default: return null
            }};
            case 4: {        
                switch (knowledgeId) {
                    case 121: return <DandDView41 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } taskObject = { props.taskObject }/>;
                default: return null
            }};
            case 5: {        
                switch (knowledgeId) {
                    case 126: return <DandDView51 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } />;
                    case 130: return <DandDView52 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } taskObject = { props.taskObject }/>;
                    case 151: return <DandDView53 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } taskObject = { props.taskObject }/>;
                    case 186: return <DandDView54 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } taskObject = { props.taskObject }/>;
                    case 189: return <DandDView55 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } taskObject = { props.taskObject }/>;
                    case 204: return <DandDView56 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } taskObject = { props.taskObject }/>;
                    case 214: return <DandDView57 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } taskObject = { props.taskObject }/>;
                    default: return null
            }};
            case 6: {        
                switch (knowledgeId) {
                    case 229: return <DandDView61 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } taskObject = { props.taskObject }/>;
                    case 248: return <DandDView62 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } taskObject = { props.taskObject }/>;
                default: return null
            }};
            case 7: {        
                switch (knowledgeId) {
                    case 275: return <DandDView71 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } taskObject = { props.taskObject }/>;
                    case 335: return <DandDView72 targetArray = { props.targetArray } targetArray2 = { props.targetArray2 } draggableArray = { props.draggableArray } taskObject = { props.taskObject }/>;
                default: return null
            }};
            default: return null
        }
    }

    return(
        <div className = {props.taskObject.taskType}>
            <div className = 'knowTaskDandDBackgroundContainer'>
                <div className = {props.taskObject.background}></div>
            </div>
            
            <div className = 'knowTaskDandDContainer'>
                {props.taskObject.taskTitle === '' ? '' : 
                    <div className = 'knowTaskDandDTitleWrapper'>
                        <div className = {'knowTaskIconContainer'}><div className = {props.taskObject.taskIcon}><div className = 'knowIcon'></div></div></div>
                        <div className = 'knowTaskDandDTitle'><h1>{props.taskObject.taskTitle}</h1></div>
                    </div>
                }
                <div className = 'knowTaskDandDViewContainer'>
                    {getTask(props.taskObject.areaId, props.taskObject.knowledgeId)}
                </div>
            </div>
            
        </div>
        
     
    )

}

export default TaskDandD