import React, { useEffect, useState, useContext } from 'react'
import PopupContext from '../../models/contexts/popupContext';
import KnowledgeContext from '../../models/contexts/knowledgeContext'
import IKnowledge from '../../models/knowledge'
import TaskInfo from '../views/TaskInfo'
import TaskTextarea from '../views/TaskTextarea'
import DandDController from "./DandDController"
import { useNavigate } from 'react-router-dom'



interface IKnowledgeControllerProps  {
    handleMenu: () => void
}

const KnowledgeController: React.FunctionComponent<IKnowledgeControllerProps> = props => {

    const { actualKnowledge } = useContext(KnowledgeContext)
    const [ intro, setIntro ] = useState('knowledgeIntroHide')
    const [ focus, setFocus ] = useState(false)
    const [ isMobile, setIsmobile ] = React.useState(false);
    let navigate = useNavigate();
    let introObject = ''

    useEffect(() => {
        //TEXTAREA MOBILNÉZET
        if(window.innerWidth < 480){ setIsmobile(true)
        }else{ setIsmobile(false) }
        window.addEventListener("resize", () => {
            if(window.innerWidth < 480){ setIsmobile(true)
            }else{ setIsmobile(false) }
        });
        
        setTimeout(() => {
            setIntro('knowledgeIntroShow')
            setTimeout(() => {setIntro('knowledgeIntroHide')}, 3200);
        }, 500);
    }, [window.innerWidth])

    const updateFocus = (focus: boolean) => { setFocus(focus) }

    const getTaskType = (tasktype: number) => {
        switch (tasktype) {
            case 1: return 'knowTaskInfo';
            case 2: return 'knowTaskTextarea';
            case 4: return 'knowTaskQuote';
            case 5: return 'knowTaskDandD';
            default: return null
          }
    }

    const getTaskIcon = (taskicon: number) => {
        switch (taskicon) {
            case 1: return 'icon-kicsiknek';
            case 2: return 'icon-folklor';
            case 3: return 'icon-foldrajz';
            case 4: return 'icon-zene';
            case 5: return 'icon-tanc';
            case 6: return 'icon-nagyoknak';
            case 7: return 'icon-tortenelem';
            case 8: return 'icon-feladat';
            case 9: return 'icon-namegegyralokest';
            case 10: return 'icon-fermegaszekerre';
            case 11: return 'icon-feladat-foldrajz';
            case 12: return 'icon-tortenelem-nagyoknak';
            case 13: return 'icon-feladat-tanc';
            case 14: return 'icon-tanc-nagyoknak';
            case 15: return 'icon-tanc-folklor';
            case 16: return 'icon-feladat-nagyoknak';
            case 17: return 'icon-folklor-nagyoknak';
            default: return null
          }
    }

    const getChapter = (areaid: number) => {
        switch (areaid) {
            case 1: return 'chapter1/';
            case 2: return 'chapter2/';
            case 3: return 'chapter3/';
            case 4: return 'chapter4/';
            case 5: return 'chapter5/';
            case 6: return 'chapter6/';
            case 7: return 'chapter7/';
            default: return null
          }
    }

    const splitter = (picture: string) => {
        let picturetemp: string[] = []
        if(picture != null){
            picturetemp = picture.split("#", 2)
        }
        return picturetemp
    }

    const doNavigate = () => { navigate("/") }

    const doRender = () => {
        return <>            
            {introObject = 'images/chapter'+actualKnowledge[0].areaid+'/knowledge'+actualKnowledge[0].areaid+'.png'}
            <div className = { intro }><img style={{ height: '90%', width: '90%' }}src = {introObject}></img></div>
            <div className = 'knowledges'>
            {actualKnowledge.map((knowledge, i) => (
                <div className = 'knowledgeWrapper' key = {i}>{taskRenderer(i, knowledge)}</div>
            ))}
            </div>
        </>
    }

    const taskRenderer = (index: number, element: IKnowledge) => {
         let taskObject = {
            background: '',
            taskType: '',
            taskTitle: 'title',
            taskIcon: '',
            taskText: '',
            taskPictureOrientation: '',
            taskPicture: '',
            taskPictureText: '', 
            areaId: 0,
            knowledgeId: 0
        }
        index %2 === 0 ? taskObject.background = 'knowTaskBackground1' : taskObject.background = 'knowTaskBackground2';
        taskObject.taskType = getTaskType(element.tasktype)!;
        taskObject.taskTitle = element.tasktitle;
        taskObject.taskIcon = getTaskIcon(element.taskicon)!;
        taskObject.taskText = element.tasktext;
        if(element.taskpicture !== ''){
            let splitted = splitter(element.taskpicture);
            taskObject.taskPictureOrientation = splitted[0];
            taskObject.taskPicture = `images/${getChapter(element.areaid)!}${splitted[1]}`;
        }
        taskObject.taskPictureText = element.taskpicturetext;
        taskObject.areaId = element.areaid;
        taskObject.knowledgeId = element.id;
        
        switch (element.tasktype) {
            case 1: {
                return <TaskInfo taskObject = { taskObject } />}
            case 2: {
                return <TaskTextarea taskObject = { taskObject } focus = { focus } updateFocus = { updateFocus } isMobile = { isMobile }/>}
            case 5: {
                return <DandDController taskObject = { taskObject }/>}
            default: return null

          }
      
    }

    return(
        <div className = 'knowledgeContainer'>
            { actualKnowledge.length === 0 ? <>{doNavigate()}</> : <>{doRender()}</>}
        </div>   
    )

}

export default KnowledgeController