import React, { useEffect, useState, useContext } from 'react'
import '../../styles/style.css';
import KnowledgeContext from '../../models/contexts/knowledgeContext'
import IWindow from '../../models/window'
import MapDuna from './MapDuna'

interface MapProps {
    handleMenu: () => void
}

const Map: React.FunctionComponent<MapProps> = props => {

    const [ dialekt, setDialekt ] = useState<string>('map');
    const { updateActualArea, updateActualKnowledge } = useContext(KnowledgeContext)

    useEffect(() => {
    }, [ dialekt])

    const handleMap = (areaid: number) => { 
        updateActualArea(areaid);
        updateActualKnowledge(areaid);
        props.handleMenu()
    }

    return(
        <div className = 'window'>
            <div className = 'windowTitle'>Válassz tájegységet a térképről!</div>
        {dialekt === 'map' ? 
            <div className = 'map'>
                <div className = 'mapWrapper'>
                    <div className = 'mapErdely' onClick = {() => setDialekt('erdely')}>
                        <div className = 'mapErdelyPinWrapper'>
                            <div>ERDÉLYI DIALEKTUS</div>
                            <div className = 'mapErdelyPin'></div>
                        </div>  
                    </div>
                    <div className = 'mapTisza' onClick = {() => setDialekt('tisza')}>
                        <div className = 'mapTiszaPinWrapper'>
                            <div>TISZAI DIALEKTUS</div>
                            <div className = 'mapTiszaPin'></div>
                        </div>
                    </div>
                    <div className = 'mapDuna' onClick = {() => setDialekt('duna')}>
                        <div className = 'mapDunaPinWrapper'>
                            <div>DUNAI DIALEKTUS</div>
                            <div className = 'mapDunaPin'></div>
                        </div>
                    </div>
                </div>
            </div>
        : dialekt === 'duna' ?
            <MapDuna handleMap = { handleMap } />
        : ''}
        </div>
     
    )

}

export default Map