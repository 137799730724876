import React, { useState, useContext, useEffect } from 'react'
import UserContext from '../models/contexts/userContext'
import PopupContext from '../models/contexts/popupContext'
import PopupController from './PopupController'
import RoutingController from './RoutingController'
import '../styles/style.css'


const AuthController: React.FunctionComponent = props => {

    const { actualUser, updateActualUser, users } = useContext(UserContext)

    const [ popupVisibility, setPopupVisibility ] = useState<boolean>(false);
    const updatePopupVisibility = (visible: boolean) => { setPopupVisibility(visible) }

    const [ popupTheme, setPopupTheme ] = useState<string>('login');
    const updatePopupTheme = (theme: string) => { setPopupTheme(theme) }

    const [ popupTitle, setPopupTitle ] = useState<string>('Bejelentkezés');
    const updatePopupTitle = (title: string) => { setPopupTitle(title) }

    const [ popupMessage, setPopupMessage ] = useState<string>('');
    const updatePopupMessage = (message: string) => { setPopupMessage(message) }

    useEffect(() => {
        /*if(actualUser.id === 0){
          setTimeout(() => { 
            updatePopupVisibility(true)
          }, 1000)
        }*/
    }, [actualUser])

    const login = (email: string) => {
        console.log('LOGIN')
           
    }

    return(
        <PopupContext.Provider value = {{popupVisibility, updatePopupVisibility, popupTheme, updatePopupTheme, popupTitle, updatePopupTitle, popupMessage, updatePopupMessage}}>
            <div className = 'controllers'>
                <div className = {popupVisibility ?  'popupContainer' : 'popupContainerInvisible'}><PopupController login = { login }/></div>
                {actualUser !== undefined ? <RoutingController /> : ''}
            </div>
        </PopupContext.Provider>
        
     
    )

}

export default AuthController