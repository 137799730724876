import React, { useEffect, useState, useContext } from 'react'
import PopupContext from '../../models/contexts/popupContext';
import KnowledgeContext from '../../models/contexts/knowledgeContext'




interface TaskTextareaProps {
    taskObject: {
        background: string,
        taskType: string,
        taskTitle: string,
        taskIcon: string,
        taskText: string,
        taskPictureOrientation: string,
        taskPicture: string,
        taskPictureText: string,
        areaId: number,
        knowledgeId: number
    },
    focus: boolean,
    updateFocus: (focus: boolean) => void,
    isMobile: boolean,
}

const TaskTextarea: React.FunctionComponent<TaskTextareaProps> = (props) => {

    const { actualKnowledge } = useContext(KnowledgeContext)
    const [ input, setInput ] = useState({ answer: '' })
    const [ answerList, setAnswerList ] = useState([])

    useEffect(() => { }, [actualKnowledge, props.focus])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):void => {
        setInput({
          ...input,
          [e.target.name]: e.target.value
        })
      }

    const handleForm = (): void => {
        let tempList = Object.assign(answerList)
        tempList.push(input.answer)
        setAnswerList(tempList)
    }


    return(
        <div className = {props.taskObject.taskType}>
            <div className = 'knowTaskTextareaBackgroundContainer'>
                <div className = {props.taskObject.background}></div>
            </div>
            <div className = 'knowTaskTextareaContainer'>
                {props.taskObject.taskTitle === null ? '' : 
                    <div className = {props.focus && props.isMobile ? 'knowTaskTextareaTitleWrapperHide' : 'knowTaskTextareaTitleWrapper'}>
                        <div className = 'knowTaskIconContainer'><div className = {props.taskObject.taskIcon}><div className = 'knowIcon'></div></div></div>
                        <div className = 'knowTaskTextareaTitle'><h1>{props.taskObject.taskTitle}</h1></div>
                    </div>
                }
                <div className = 'knowTaskTextareaInputContainer'>
                    <textarea className="knowTaskTextareaInput" name = 'answer' placeholder = 'Írd ide a válaszod!' onChange={handleChange} value = {input.answer} onFocus={() => props.updateFocus(true)} onBlur={() => props.updateFocus(false)}></textarea>
                    <button className = 'butt' onClick = {() => handleForm()}>MENTÉS</button>
                </div>
                {props.taskObject.taskPicture === '' ? '' : 
                    props.taskObject.taskPictureOrientation === 'portrait' ?
                    <div className = {props.focus && props.isMobile ? 'knowTaskTextareaPicHide' : 'knowTaskTextareaPic'}>
                        <img style={{ borderRadius: '1.5rem', height: '100%', width: '100%' }}src = {props.taskObject.taskPicture}></img>
                        {props.taskObject.taskPictureText === null ? '' : <div className = 'knowTaskTextareaPicText'><p>{props.taskObject.taskPictureText}</p></div>}
                    </div>
                    : <div className = {props.focus && props.isMobile ? 'knowTaskTextareaPicLandscapeHide' : 'knowTaskTextareaPicLandscape'}>
                        <img style={{ borderRadius: '1.5rem', height: '100%', width: '100%' }}src = {props.taskObject.taskPicture}></img>
                        {props.taskObject.taskPictureText === null ? '' : <div className = {props.focus && props.isMobile ? 'knowTaskTextareaPicTextHide' : 'knowTaskTextareaPicText'}><p>{props.taskObject.taskPictureText}</p></div>}
                      </div>
                }
            </div>
        </div>

    )

}

export default TaskTextarea