import React, { useEffect } from 'react'
import '../../styles/style.css';

interface MenuArrowProps {
  arrowStyle: string,
  updateArrowOut: () => void
}

const MenuArrow: React.FunctionComponent<MenuArrowProps> = props => {

    
    useEffect(() => {
    }, [props.arrowStyle])


    return(
      <div className = 'menuButtonArrowWrapper' onClick = {() => props.updateArrowOut()}>
        <div className = { props.arrowStyle }></div>
      </div>
    )

}

export default MenuArrow