import { createContext } from 'react';
import IUsers from '../interfaces/userInterface';


const defaultState = {
   actualUser: {id: 0, name: '', email: ''},
   updateActualUser: () => {},
   users: [{id: 0, name: '', email: ''}],
   updateUsers: () => {},
};

const UserContext = createContext<IUsers>(defaultState);

export default UserContext