import React, { useEffect } from 'react'
import '../../styles/style.css';

interface MapDunaProps {
    handleMap: (areaid: number) => void
}

const MapDuna: React.FunctionComponent<MapDunaProps> = props => {

     useEffect(() => {
    }, [])


    return(
        <div className = 'duna'>
            <div className = 'duna1Wrapper' onClick = {() => props.handleMap(4)}>
                <div className = 'duna1'>
                    <div className = 'duna1Text'>Palócok</div>
                </div>
            </div>
            <div className = 'duna2Wrapper' onClick = {() => props.handleMap(6)}>
                <div className = 'duna2'>
                    <div className = 'duna2Text'>Dél-Alföld</div>
                </div>
            </div>
            <div className = 'duna3Wrapper' onClick = {() => props.handleMap(3)}>
                <div className = 'duna3'>
                    <div className = 'duna3Text'>Rábaköz</div>
                </div>
            </div>
            <div className = 'duna4Wrapper' onClick = {() => props.handleMap(5)}>
                <div className = 'duna4'>
                    <div className = 'duna4Text'>Nyírség</div>
                </div>
            </div>
            <div className = 'duna5Wrapper' onClick = {() => props.handleMap(1)}>
                <div className = 'duna5'>
                    <div className = 'duna5Text'>Somogy</div>
                </div>
            </div>
            <div className = 'duna6Wrapper' onClick = {() => props.handleMap(2)}>
                <div className = 'duna6'>
                    <div className = 'duna6Text'>Sárköz</div>
                </div>
            </div>
            <div className = 'duna7Wrapper' onClick = {() => props.handleMap(7)}>
                <div className = 'duna7'>
                    <div className = 'duna7Text'>Szék</div>
                </div>
            </div>
            <div className = 'duna8Wrapper' onClick = {() => props.handleMap(8)}>
                <div className = 'duna8'>
                    <div className = 'duna8Text'>Kiskunság</div>
                </div>
            </div>
        </div>
     
    )

}

export default MapDuna