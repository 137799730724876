import React, { useEffect, useState, useContext } from 'react'
import PopupContext from '../../models/contexts/popupContext';
import KnowledgeContext from '../../models/contexts/knowledgeContext'




interface TaskInfoProps {
    taskObject: {
        background: string,
        taskType: string,
        taskTitle: string,
        taskIcon: string,
        taskText: string,
        taskPictureOrientation: string,
        taskPicture: string,
        taskPictureText: string,
        areaId: number,
        knowledgeId: number
    }

}

const TaskInfo: React.FunctionComponent<TaskInfoProps> = (props) => {

    const { actualKnowledge } = useContext(KnowledgeContext)

    useEffect(() => {

        
    }, [actualKnowledge])

    const handleClick = (email: string) => {
  
      
     }

    return(
        <div className = {props.taskObject.taskType}>
            <div className = 'knowTaskInfoBackgroundContainer'>
                <div className = {props.taskObject.background}></div>
            </div>
            
            <div className = 'knowTaskInfoContainer'>
                {props.taskObject.taskTitle === null ? '' : 
                    <div className = 'knowTaskInfoTitleWrapper'>
                        <div className = {'knowTaskIconContainer'}><div className = {props.taskObject.taskIcon}><div className = 'knowIcon'></div></div></div>
                        <div className = 'knowTaskInfoTitle'><h1>{props.taskObject.taskTitle}</h1></div>
                    </div>
                }
                {props.taskObject.taskText === null ? '' : 
                    <div className = 'knowTaskInfoTextWrapper'>
                        <div className = {'knowTaskIconContainer'}><div className = {props.taskObject.taskIcon}><div className = 'knowIcon'></div></div></div>
                        {props.taskObject.taskText === '' ? '' : <div className = 'knowTaskInfoText'><p>{props.taskObject.taskText}</p></div>}
                    </div>
                }
                {props.taskObject.taskPicture === '' ? '' : 
                    props.taskObject.taskPictureOrientation === 'portrait' ?
                    <div className = 'knowTaskInfoPic'>
                        <img style={{ borderRadius: '1.5rem', height: '100%', width: '100%' }}src = {props.taskObject.taskPicture}></img>
                        {props.taskObject.taskPictureText === null ? '' : <div className = 'knowTaskInfoPicText'><p>{props.taskObject.taskPictureText}</p></div>}
                    </div>
                    : <div className = 'knowTaskInfoPicLandscape'>
                        <img style={{ borderRadius: '1.5rem', height: '100%', width: '100%' }}src = {props.taskObject.taskPicture}></img>
                        {props.taskObject.taskPictureText === null ? '' : <div className = 'knowTaskInfoPicText'><p>{props.taskObject.taskPictureText}</p></div>}
                      </div>
                }
            </div>
            
        </div>
        
     
    )

}

export default TaskInfo