import React, { useState, useEffect, useContext } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import '../../../styles/style.css';



function DandDView51( { targetArray, targetArray2, draggableArray}) {

  /* _____________________________________________________________________________________________________________________ VARIABLES */

 



  const [ columns, setColumns ] = useState([])
  let dragItems = []
  let targetItems = {}

/* _____________________________________________________________________________________________________________________ USEEFFECT */
    useEffect(() => {

    for(let i = 0; i < draggableArray.length; i++){
      let dragElement = {}
      dragElement.id = i.toString();
      dragElement.content = draggableArray[i]
      dragItems.push(dragElement)

    }

    let targetElement = {}
    targetElement.name = '1'
    targetElement.text = ''
    targetElement.items = dragItems
    targetElement.style = 'board1'
    targetItems[1] = targetElement

    for(let n = 0; n < targetArray.length; n++){
      let targetElement2 = {}
      targetElement2.name = `${n+2}`
      targetElement2.text = targetArray[n]
      targetElement2.items = new Array
      targetElement2.style = `board${n+2}`
      targetItems[`${n+2}`] = targetElement2

    }

      setColumns(targetItems)
    }, [])

/* _____________________________________________________________________________________________________________________ FUNCTIONS */
const onDragEnd = (result, columns, setColumns) => {
  //console.log('RESULT ', result.draggableId)
  if (!result.destination) return;
  const { source, destination } = result;

  const sourceColumn = columns[source.droppableId];
  const destColumn = columns[destination.droppableId];
  const sourceItems = [...sourceColumn.items];
  const destItems = [...destColumn.items];

  if(parseInt(destination.droppableId) === (parseInt(result.draggableId) +2)){
    //console.log('IDMATCH ', destination.droppableId, ' DRAG ', parseInt(result.draggableId) +2)
    if (source.droppableId !== destination.droppableId) {

      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      //console.log('IDMATCH ELSE DROP ', destination.droppableId, ' DRAG ', parseInt(result.draggableId) + 2)
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  }else{
    //console.log('ID NOT MATCH DROP ', destination.droppableId, ' DRAG ', parseInt(result.draggableId) + 2)
    return
  }

};

/* _____________________________________________________________________________________________________________________ RETURN */


  return (
    <div className = 'knowDandDView51'>
      <div className = 'knowTaskDandDTargetWrapper' > 
        {Object.entries(targetArray).map((targetElement, index) => {
          return (
          <div className = 'knowTaskDandDTargetRow' key = { index }>
            <div className = 'knowTaskDandDTargetText1'> {targetArray[index]} </div>
            <div className = 'knowTaskDandDTargetText2'> {targetArray2[index]} </div>
          </div>
          )
        })}
     </div> 
        <div className = 'taskDandDProps'>
        <div className = 'boards'>
            <DragDropContext onDragEnd={result => onDragEnd(result, columns, setColumns)} >
              {Object.entries(columns).map(([columnId, column], index) => {
                return (
                  <div className = {column.style} key={columnId} >
                    <div className = 'boardWrapper'>
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          return (
                            <>
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className = 'dropArea'
                              style={{ background: snapshot.isDraggingOver ? "lightblue" : "",}} >
                                {column.items.map((item, index) => {
                                  return (
                                    <Draggable key={item.id} draggableId={item.id} index={index} >
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className = 'dragElement'
                                            style={{ userSelect: "none", ...provided.draggableProps.style }}
                                          >
                                            <div className = 'dragElementTextWrapper'><p>{item.content}</p></div>
                                            
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                                
                            </div>
                            
                            </>
                          );
                        }}
                      </Droppable>
                    </div>
                  </div>
                );
              })}
            </DragDropContext>
          </div>
        </div>
        
    </div>

  );

  }


export default DandDView51;
