import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import ContextController from './controllers/ContextController';
import { vakeup } from './tools/Queries'

function App() {

  const [ loading, setLoading ] = useState(false);

  useEffect(() =>{
    console.log('APP USEEFFECT ', loading)
  }, [])

  return (
    <div className = 'canvas'>
      {loading ? <div className = 'canvas'><ReactLoading type={'spinningBubbles'} color={'#808080'} height={'10%'} width={'10%'}  /> </div>
      : <ContextController />}
      </div>
  );
}

export default App;
