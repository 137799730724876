import React, { useEffect, useState, useContext } from 'react'
import '../../styles/style.css';
import Window from '../../tools/Window'
import WindowContext from '../../models/contexts/windowContext'
import IWindow from '../../models/window';

interface GreetingProps {

}

const Greeting: React.FunctionComponent<GreetingProps> = props => {

    const [ title, setTitle ] = useState<string>('openTitleWrapperHide')

    useEffect(() => {
        setTimeout(() => {setTitle('openTitleWrapperShow')}, 3000);
    }, [])


    return(
        <div className = 'contentContainer'>
            <div className = 'greeting'>
                <div className = { title }>
                    <div className = 'window'>
                        <div className = 'greetContainer'>
                            <div className = 'greetleft'>
                                <div className = 'greetintroTitle'>Kedves Táncos Fiatalok!</div>
                                <div className = 'greetlacibacsi'></div>                             
                            </div>
                            <div className = 'greetright'>
                                <div className = 'greetintroText'>
                                    A táncolás öröme semmi mással össze nem hasonlítható!
                                    Tudják ezt mindazok, akik már megtapasztalták a tánc által közvetített őserőt, amely zsigereinkig hatolva energiával, lendülettel, egészséggel, önfeledt örömmel tölt el mindannyiunkat.
                                    <br></br><br></br>
                                    Kívánjuk, hogy merítkezzetek meg minél többen ebben a kitörő örömben! Ismerjétek meg nemzetünk táncait, az általuk közvetített érzéseket, a hozzájuk kapcsolódó
                                    néphagyományokat! Tisztelettel és alázattal merítsetek a tiszta forrásból! Értékek őrzői, tisztelői, hagyományozói legyetek, hogy nemzedékről nemzedékre fennmaradjon
                                    néptánc örökségünk!
                                    <br></br><br></br>Legyetek érdeklődőek és kíváncsiak!
                                    <br></br><br></br>
                                    A táncok kialakulásának történelmi, földrajzi háttere, zenei jellemzői megannyi izgalmas és érdekes kérdést vet fel. Jó tudni mindezeket!
                                    Fedezzétek fel ezt az érdekes és izgalmas világot, amely a múltban gyökerezik, de a jelenünkre is hatással van és a jövőnket is meghatározza!
                                    <br></br><br></br>Jókedvűen használjátok a Néptáncoskolát, a feladatok megoldásában legyetek kezdeményezők, kreatívak és kitartók!
                                    A munkátok, kitartásotok eredménye nem maradhat el: tudás, egészség, közösséghez tartozás, sok-sok élmény, szeretet és öröm.
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
     
    )

}

export default Greeting