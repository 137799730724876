import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import MenuView from '../OPEN/views/MenuView'
import Greeting from '../OPEN/views/Greeting'
import Map from '../OPEN/views/Map'
import KnowledgeController from '../KNOWLEDGE/controllers/KnowledgeController'


interface RoutingProps {
 
}

const RoutingController: React.FunctionComponent<RoutingProps> = props => {

    
    const [ menuStyle, setMenuStyle ] = useState<string>('menuContainerHide')
    const [ arrowOut, setArrowOut ] = useState<boolean>(true)
    const updateArrowOut = () => { 
        setArrowOut(!arrowOut)
        if(arrowOut){
            setMenuStyle('menuContainerShow')
          }else{
            setMenuStyle('menuContainerHide')
          }
    }

    useEffect(() => {

    }, [])

    const handleMenu = () => {
        if(menuStyle === 'menuContainerShow'){
            setTimeout(() => {
                setMenuStyle('menuContainerHide');
                updateArrowOut();
            }, 1500);
        }else{
            setTimeout(() => {
                setMenuStyle('menuContainerShow');
                updateArrowOut();
            }, 1000);
        }
        

    }


    return(
        <BrowserRouter >
            <div className = { menuStyle }>
                <MenuView menuStyle = { menuStyle } arrowOut = { arrowOut } updateArrowOut = { updateArrowOut } handleMenu = { handleMenu }/>
            </div>
            <div className = 'contentContainer'>
                <Routes>
                    <Route path = "/" element = { <Greeting /> }></Route>
                    <Route path = "/areaChooser" element = { <Map handleMenu = { handleMenu }/> }></Route>
                    <Route path = "/knowledge" element = { <KnowledgeController handleMenu = { handleMenu } /> }></Route>
                    <Route path = "*" element = { <Greeting /> }></Route>
                </Routes>
            </div>
        </BrowserRouter>
    )

}

export default RoutingController