import React, { useEffect, useState } from 'react'
import PopupMessage from './PopupMessage';
import '../../styles/style.css'

interface IPopupProps {
    popupVisibility: boolean,
    popupTheme: string,
    popupMessage: string,
    popupTitle: string,
    popupStyle: string,
    handleClick: ( email: string ) => void
}

const PopupController: React.FunctionComponent<IPopupProps> = (props) => {

    const [ width, setWidth ] = useState(window.innerWidth);
    const [ height, setHeight ] = useState(window.innerHeight);
    const [ keyboard, setKeyboard ] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
        window.addEventListener("resize", () => setHeight(window.innerHeight));

    }, [props.popupStyle])


  
       



    return(
        <div className = { props.popupStyle }>
            <div className = 'popupTitleWrapper'>
                <div className = 'icon'></div>
                <div>{props.popupTitle}</div>
            </div>
            <div className = 'popupContentWrapper'>
                POPUP
            </div>
        </div>
        
     
    )

}

export default PopupController