import React, { useEffect, useState, useContext } from 'react'
import TaskDandD from '../views/TaskDandD'



interface IDandDControllerProps {
    taskObject: {
        background: string,
        taskType: string,
        taskTitle: string,
        taskIcon: string,
        taskText: string,
        taskPictureOrientation: string,
        taskPicture: string,
        taskPictureText: string,
        areaId: number,
        knowledgeId: number
    }
}

const DandDController: React.FunctionComponent<IDandDControllerProps> = (props) => {

    let draggableArray: string[] = []
    let targetArray: string[] = []
    let targetArray2: string[] = []

    useEffect(() => {

    }, [])

    const splitter = (taskText: string) => {
        
        let categoryTemp = taskText.split("@");
        let tempArray: string[] = []

        if(categoryTemp[0] != undefined){ tempArray = categoryTemp[0].split("#") }
        for(let i = 0; i < tempArray.length; i++){
            if(i === 0 || (i % 2) === 0){
                
                targetArray.push(tempArray[i]);
                targetArray2.push(tempArray[(i + 1)]);
            }

        }
        
        if(categoryTemp[1] != undefined){ draggableArray = categoryTemp[1].split("#") }

        return <TaskDandD taskObject = { props.taskObject } targetArray = { targetArray } targetArray2 = { targetArray2 } draggableArray = { draggableArray }/>
        
    }


    return(
        <>
            
            {splitter(props.taskObject.taskText)}
        </>
        
     
    )

}

export default DandDController