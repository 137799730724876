import React, { useEffect } from 'react'
import '../../styles/style.css';

interface SubMenuProps {
  menu: any[]
  handleSubMenu: (choosed: string) => void
}

const SubMenu: React.FunctionComponent<SubMenuProps> = props => {

    
    useEffect(() => {

    }, [])


    return(
      <>
      <div className = 'menuSubMenuTitle'>Tudáselemek</div>
      <div className = 'menuSubMenu' >
        
        <div className = 'menuSubmenuColumn'>
          <div className = 'menuKnowledgeButton' onClick = {() => props.handleSubMenu('knowledge')}>
            <div className = 'mButton'>
              <div className = 'mButtonText'>Tudásbázis</div>
            </div>
          </div>
          <div className = 'menuSheetmusicButton' onClick = {() => props.handleSubMenu('sheetmusic')}>
            <div className = 'mButton'>
              <div className = 'mButtonText'>Népdalkották</div>
            </div>
          </div>
          <div className = 'menuRecordButton' onClick = {() => props.handleSubMenu('record')}>
            <div className = 'mButton'>
              <div className = 'mButtonText'>Népdal hangzó anyagok</div>
            </div>
          </div>
          <div className = 'menuArchiveFootageButton' onClick = {() => props.handleSubMenu('archive')}>
            <div className = 'mButton'>
              <div className = 'mButtonText'>Archív táncfelvételek</div>
            </div>
          </div>
        </div>
        <div className = 'menuSubmenuColumn'>
          <div className = 'menuTaskButton' onClick = {() => props.handleSubMenu('task')}>
            <div className = 'mButton'>
              <div className = 'mButtonText'>Segítő feladatok</div>
            </div>
          </div>
          <div className = 'menuDanceButton' onClick = {() => props.handleSubMenu('dance')}>
            <div className = 'mButton'>
              <div className = 'mButtonText'>Motívumtár</div>
            </div>
          </div>
          <div className = 'menuFootageButton' onClick = {() => props.handleSubMenu('footage')}>
            <div className = 'mButton'>
              <div className = 'mButtonText'>Táncfelvételek</div>
            </div>
          </div>
          <div className = 'menuFolkloreButton' onClick = {() => props.handleSubMenu('folklore')}>
            <div className = 'mButton'>
              <div className = 'mButtonText'>Folklórismeret</div>
            </div>
          </div>
        </div>  
      </div>
      </>
     
    )

}

export default SubMenu