import axios, { AxiosResponse } from 'axios'
import IUser from '../models/user';
import IKnowledge from '../models/knowledge'
let temp: IUser;
let userArray: IUser[] = [];
let menuArray: any = [];

let tempKnowledge: IKnowledge;
let knowledgeArray: IKnowledge[] = [];


//Promise IKnowledge típusú objektumokat tartalmazó tömbbel tér vissza
export const vakeup = () => {
  return new Promise<string>((resolve, reject) => {
    axios.get<string>('https://homaly.herokuapp.com/')
    .then((response: AxiosResponse) => { resolve('OK'); })
    .catch(error => { reject(error); })
  })
};


//Promise IUser típusú objektumokat tartalmazó tömbbel tér vissza
export const getUsers = () => {
  userArray.length = 0;
  return new Promise<IUser[]>((resolve, reject) => {
    axios.get<IUser>('https://homaly.herokuapp.com/neptancoskola/allusers')
    .then((response: AxiosResponse) => {
        response.data.map((element: IUser) => (
        (temp = {id: element.id, name: element.name, email: element.email},
        userArray.push(temp))
        ))
      resolve(userArray);
    })
    .catch(error => {
      reject(error);
    })
  })
};

//Promise IUser típusú objektumokat tartalmazó tömbbel tér vissza
export const getMenu = () => {
  userArray.length = 0;
  let menu = {};
  return new Promise<any[]>((resolve, reject) => {
    axios.get<IUser>('https://homaly.herokuapp.com/neptancoskola/fetchmenu')
    .then((response: AxiosResponse) => {
        response.data.map((element: any) => (
          
        (menu = {dialectid: +element.dialectid, dialect: element.dialect, areaid: +element.areaid, area: element.area},
        menuArray.push(menu))
        ))
      resolve(menuArray);
    })
    .catch(error => {
      reject(error);
    })
  })
};
let x
let y: string
//Promise IKnowledge típusú objektumokat tartalmazó tömbbel tér vissza
export const getKnowledges = () => {
  return new Promise<IKnowledge[]>((resolve, reject) => {
    axios.get<IKnowledge>('https://homaly.herokuapp.com/neptancoskola/fetchknowledge')
    .then((response: AxiosResponse) => {
        knowledgeArray.length = 0
        response.data.map((element: IKnowledge) => (
        (tempKnowledge = {areaid: +element.areaid, id: +element.id, tasktype: +element.tasktype, tasktitle: element.tasktitle, taskicon: +element.taskicon, tasktext: element.tasktext, taskpicture: element.taskpicture, taskpicturetext: element.taskpicturetext},
        knowledgeArray.push(tempKnowledge))
        ))
      resolve(knowledgeArray);
    })
    .catch(error => {
      reject(error);
    })
  })
};
