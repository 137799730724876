import React, { useEffect, useState, useContext } from 'react'
import '../../styles/style.css';
import { useNavigate } from 'react-router-dom'
import MenuArrow from '../components/MenuArrow'
import UserButton from '../components/UserButton'
import AreaButton from '../components/AreaButton'
import UserContext from '../../models/contexts/userContext'
import KnowledgeContext from '../../models/contexts/knowledgeContext'
import SubMenu from '../components/SubMenu'
import { getMenu } from '../../tools/Queries'

interface MenuProps {
  menuStyle: string,
  arrowOut: boolean,
  updateArrowOut: () => void
  handleMenu: () => void
}

const MenuView: React.FunctionComponent<MenuProps> = props => {

    const navigate = useNavigate()
    const [ arrowStyle, setArrowStyle ] = useState<string>('menuButtonArrowOut')
    const [ signedUser, setSignedUser ] = useState<string>('')
    const { actualUser } = useContext(UserContext)
    const [ clickedArea, setClickedArea ] = useState<string>('válassz tájegységet!')
    const { actualArea } = useContext(KnowledgeContext)
    const [ menu, setMenu ] = useState<any[]>([
      {id: 1, dialectid: 1, dialect: 'Dunai táncdialektus', areaid: 1, area: 'Északnyugati dialektus'},
      {id: 2, dialectid: 1, dialect: 'Dunai táncdialektus', areaid: 2, area: 'Csallóköz, Szigetköz'},
      {id: 3, dialectid: 1, dialect: 'Dunai táncdialektus', areaid: 3, area: 'Rábaköz'},
      {id: 4, dialectid: 1, dialect: 'Dunai táncdialektus', areaid: 4, area: 'Közép-Dunántúl'},
      {id: 5, dialectid: 1, dialect: 'Dunai táncdialektus', areaid: 5, area: 'Somogy'},
      {id: 6, dialectid: 1, dialect: 'Dunai táncdialektus', areaid: 6, area: 'Sárköz'},
      {id: 7, dialectid: 1, dialect: 'Dunai táncdialektus', areaid: 7, area: 'Kalocsa-vidék'},
      {id: 8, dialectid: 1, dialect: 'Dunai táncdialektus', areaid: 8, area: 'Kiskunság'},
      {id: 9, dialectid: 2, dialect: 'Tiszai táncdialektus', areaid: 9, area: 'Felső-Tisza-vidék'},
      {id: 10, dialectid: 2, dialect: 'Tiszai táncdialektus', areaid: 10, area: 'Északkeleti Felvidék'},
      {id: 11, dialectid: 2, dialect: 'Tiszai táncdialektus', areaid: 11, area: 'Keleti palócok és matyók'},
      {id: 12, dialectid: 2, dialect: 'Tiszai táncdialektus', areaid: 12, area: 'Nagykunság, Jászság'},
      {id: 13, dialectid: 2, dialect: 'Tiszai táncdialektus', areaid: 13, area: 'Dél-Alföld'},
      {id: 14, dialectid: 3, dialect: 'Erdélyi táncdialektus', areaid: 14, area: 'Kalotaszeg'},
      {id: 15, dialectid: 3, dialect: 'Erdélyi táncdialektus', areaid: 15, area: 'Mezőség'},
      {id: 16, dialectid: 3, dialect: 'Erdélyi táncdialektus', areaid: 16, area: 'Maros-Küküllő-vidék'},
      {id: 17, dialectid: 3, dialect: 'Erdélyi táncdialektus', areaid: 17, area: 'Marosszék'},
      {id: 18, dialectid: 3, dialect: 'Erdélyi táncdialektus', areaid: 18, area: 'Székelység'},
      {id: 19, dialectid: 3, dialect: 'Erdélyi táncdialektus', areaid: 19, area: 'Barcaság, hétfalusi csángók'},
      {id: 20, dialectid: 3, dialect: 'Erdélyi táncdialektus', areaid: 20, area: 'Gymesi csángók'},
      {id: 21, dialectid: 3, dialect: 'Erdélyi táncdialektus', areaid: 21, area: 'Bukovinai székelység'},
      {id: 22, dialectid: 3, dialect: 'Erdélyi táncdialektus', areaid: 22, area: 'Moldvai csángók'},
    ])

    useEffect(() => {
      
      if(menu.length === 0){
        /*
        setTimeout(() => {
          getMenu()
          .then(response => { 
             setMenu(response)
          }).catch(error => { console.log('FETCHMENU ERROR ', error) })
        }, 200);
        */
      }

      if(props.arrowOut){
        setArrowStyle('menuButtonArrowOut')
      }else{
        setArrowStyle('menuButtonArrowIn')
      }

      if(actualUser.id === 0){
        setSignedUser('jelentkezz be!')
      }else{
        setSignedUser(actualUser.name)
      }

    }, [props.arrowOut, menu])

    const updateUser = () => { }

    const updateArea = () => {
      props.handleMenu()
      navigate('/areaChooser') 
    }

    const handleSubMenu = (choosed: string) => { 
      props.handleMenu()
      navigate(choosed) 
    }

    return(
      <>
        <div className = 'menuButton'>
          <MenuArrow arrowStyle = { arrowStyle } updateArrowOut = { props.updateArrowOut }/>
        </div>
        <div className = 'menuBar'>
          <div className = 'menuUserButtonContainer'>
            <UserButton signedUser = { signedUser } updateUser = { updateUser }/>
            <AreaButton clickedArea = { clickedArea } updateArea = { updateArea }/>
          </div>
          {actualArea !== 0 ?
            <div className = 'menuSubMenuContainer'><SubMenu handleSubMenu = { handleSubMenu } menu = { menu } /></div>
            : ''}
        </div>
      </>   
    )

}

export default MenuView