import React, { useEffect, useState } from 'react';
import IUser from '../models/user';
import IKnowledge from '../models/knowledge'
import UserContext from '../models/contexts/userContext';
import KnowledgeContext from '../models/contexts/knowledgeContext'
import AuthController from './AuthController';
import { getUsers, getKnowledges } from '../tools/Queries'


const ContextController: React.FunctionComponent = props => {

// ---------------------------------------------------------------------------------USER
    const [ users, setUsers ] = useState<IUser[]>([]);

 
    const updateUsers = () => {
      getUsers()
      .then(response => {
        setUsers(response)
        }).catch(error => { console.log('GETUSERS ERROR ', error) })
     }

    const [ actualUser, setActualUser ] = useState<IUser>({id: 0, name: '', email: ''});
    const updateActualUser = (user: IUser) => { setActualUser(user) }

// ---------------------------------------------------------------------------------KNOWLEDGE    
    const [ knowledges, setKnowledges ] = useState<IKnowledge[]>([
      {id: 1, areaid: 3, tasktype: 1, tasktitle: 'Nézzétek meg a térképet, hol található Rábaköz?', taskicon: 11, tasktext: 'Magyarország térképe', taskpicture: 'landscape#rabakoz_1.jpg' , taskpicturetext: 'Rábaköz'},
      {id: 2, areaid: 3, tasktype: 1, tasktitle: 'Rábaköz Észak-Dunántúlon, a Kisalföld déli részén található. A Rába folyó alsó folyása, valamint a Rábca és a Répce folyók fogják közre.', taskicon: 0, tasktext: '', taskpicture: '' , taskpicturetext: ''},
      {id: 3, areaid: 3, tasktype: 2, tasktitle: 'A térkép segítségével nevezd meg Rábaköz nevezetes falvait!', taskicon: 8, tasktext: '', taskpicture: 'landscape#rabakoz_1.jpg' , taskpicturetext: 'Rábaköz'},
      {id: 4, areaid: 3, tasktype: 1, tasktitle: 'Földrajzi feladat', taskicon: 3, tasktext: 'A térképen halvánnyal jelölt terület a nyugati dialektus, melyet összeköt a táncok hasonlósága.', taskpicture: 'portrait#rabakoz_2.jpg' , taskpicturetext: 'Nyugati dialektus'},
      {id: 5, areaid: 3, tasktype: 1, tasktitle: 'Folklór, történelem', taskicon: 12, tasktext: 'Rábaköz jellegzetes férfitáncával, a verbunkkal válik a nyugati dialektus egyik legfontosabb pontjává. E táncnak jelentõs szerepe volt a rábaközi parasztság körében, ennek központjában az élénk és nagyhírû legényélet állt. Ezen a vidéken szervezett legénybandák, azaz legénycéhek színesítették a falvak hétköznapjait.', taskpicture: 'landscape#rabakoz_3.jpg' , taskpicturetext: 'Csárdás. Vitnyéd, Gyõr megye (Gyûjtötte: Martin György, 1956)'},
      {id: 6, areaid: 3, tasktype: 2, tasktitle: 'Gyûjtõmunka: Nézzetek utána, mikor és hol találkoztatok már a céh kifejezéssel! Fogalmazzátok meg, mit is jelent!', taskicon: 8, tasktext: '', taskpicture: '' , taskpicturetext: ''},
      {id: 7, areaid: 3, tasktype: 1, tasktitle: 'Folklór, történelem', taskicon: 6, tasktext: 'A legénycéhek életének egyik legfontosabb eseménye a búcsú volt. Ilyenkor a falu nevezetesebb pontjain a különbözõ legénycéhek bemutatták táncaikat a búcsúba érkezõ sokaságnak. A bemutatkozásra való felkészülést, gyakorlást nagyon komolyan vették, a pontatlanság, figyelmetlenség soha nem maradt büntetlenül.', taskpicture: 'landscape#rabakoz_4.jpg' , taskpicturetext: 'Szany, Gyõr-Sopron megye (Gyûjtötte: Pesovár Ernõ, 1971)'},
      {id: 8, areaid: 3, tasktype: 1, tasktitle: 'Folklór, történelem', taskicon: 1, tasktext: 'A rábaközi legények a verbunkot általában csoportosan, körben táncolták. Ezt körverbunknak nevezzük. Elõfordult, hogy kötött, elõre kitalált mozdulatsort táncoltak, de volt olyan is, amikor minden legény a kör formáját megtartva,  szabadabban járhatta a verbunkot. A rábaközi verbunkot általában a „hejlegények” irányították. Ezek a „hejlegények” nevüket onnan kapták, hogy tánc közben hangos kiáltással jelezték a többieknek, hogy a táncban valamilyen új dolog kezdõdött.', taskpicture: '' , taskpicturetext: ''},
      {id: 9, areaid: 3, tasktype: 1, tasktitle: 'Folklór, történelem', taskicon: 7, tasktext: 'A verbunk elnevezése német eredetû, a werben (toborozni) igébõl származik. Ezt a zenéstáncos mulatást gyakran katonák toborzására, úgynevezett verbuválásra is használták. A katonák kevélykedõ, magakelletõ mozgása sehol sem volt ismeretlen a nép elõtt. A toborzás sikeréért a tisztek kiválasztották a legjobb táncos katonákat, a falu legényeinek pedig csillogó aranyat és bort kínáltak. Amelyik bámészkodó falusi férfi nem vigyázott, annak bizony csákót nyomtak a fejébe, és 6–8 évig ehette a császár kenyerét, azaz szolgált az uralkodó seregében.', taskpicture: 'landscape#rabakoz_6.jpg' , taskpicturetext: 'Gyalogos katona verbuválása (Ismeretlen mûvésztõl, 1850 körül)'},
      {id: 10, areaid: 3, tasktype: 2, tasktitle: 'A verbunk új stílusú férfitánc. Mely idõszakra tehetõ az új stílusú táncok kialakulása?', taskicon: 13, tasktext: '', taskpicture: '' , taskpicturetext: ''},
    ]);

    const updateKnowledges = () => {
      getKnowledges()
      .then(response => {
        setKnowledges(response)
        }).catch(error => { console.log('GETITEMS ERROR ', error) })
        
    }

    const [ actualArea, setActualArea ] = useState<number>(0);
    const updateActualArea = (areaid: number) => { setActualArea(areaid) }

    const [ actualKnowledge, setActualKnowledge ] = useState<IKnowledge[]>([]);
    const updateActualKnowledge = (areaid: number) => {
      let filteredArray = knowledges.filter((element) => (element.areaid === areaid))
      setActualKnowledge(filteredArray)
      console.log('CONTEXTKONT FITLEREDARR ', filteredArray)
    }

    useEffect(() =>{
      if(users.length === 0){
        console.log('CONTEXT USEEFFECT')
        //getUsers()
        //.then(response => { 
          //response.sort((a, b) => a.name.localeCompare(b.name))
          //setUsers(response)
        //}).catch(error => { console.log('GETUSERS ERROR ', error) })
/*
        getKnowledges()
        .then(response => { 
          response.sort((a, b) => (a.id) - (b.id))

          setKnowledges(response)
        }).catch(error => { console.log('GETKNOWLEDGES ERROR ', error) })
        */
      }
      

    }, [])

    return(
        <KnowledgeContext.Provider value = {{knowledges, updateKnowledges, actualArea, updateActualArea, actualKnowledge, updateActualKnowledge }}>
            <UserContext.Provider value = {{actualUser, updateActualUser, users, updateUsers}}>
              <AuthController />
            </UserContext.Provider>
        </KnowledgeContext.Provider >
        
     
    )

}

export default ContextController