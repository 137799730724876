import React, { useEffect, useState, useContext } from 'react'
import Popup from '../OPEN/components/Popup'
import PopupContext from '../models/contexts/popupContext';



interface IPopupControllerProps {
    login: ( email: string ) => void
}

const PopupController: React.FunctionComponent<IPopupControllerProps> = (props) => {

    const { popupVisibility, updatePopupVisibility, popupTheme, popupMessage, popupTitle } = useContext(PopupContext)
    const [ popupStyle, setPopupStyle ] = useState('popupHide');

    useEffect(() => {
      if(popupVisibility){
        setTimeout(() => { 
          setPopupStyle('popupShow')
        }, 500)
      }
    }, [popupVisibility])

    const handleClick = (email: string) => {
        setPopupStyle('popupHide');
        setTimeout(() => { 
            updatePopupVisibility(false)
            props.login(email)
        }, 1000)
      
     }

    return(
        <>
            <Popup popupVisibility = { popupVisibility } popupTheme = { popupTheme } popupTitle = { popupTitle } popupMessage = { popupMessage } popupStyle = { popupStyle } handleClick = { handleClick }/>
        </>
        
     
    )

}

export default PopupController